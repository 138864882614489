<template>
  <div class="createWorkTalentDetail">
    <div class="createWorkTalentDetail-b">
      <span class="name">{{ detail.topicTitle }}</span>
      <div class="createWorkTalentDetail-t">
        <img :src="detail.topicPicture" alt="" />
      </div>

      <div class="intro">
        <div class="remark">{{ detail.remark }}</div>
        <div v-html="detail.topicContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTalentInfoByIdURL } from "./api.js";
import { gloabalCount } from "@/utils/common";
export default {
  name: "createWorkTalentDetail",
  data() {
    return {
      detail: {},
      bannerList: [],
      id: "",
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getTalentDetailList();
    gloabalCount(this.id, 41, 1);
  },
  methods: {
    getTalentDetailList() {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      this.$axios
        .get(`${getTalentInfoByIdURL}`, { params: params })
        .then((res) => {
          console.log(res, 333);
          if (res.code === 200) {
            this.detail = res.data;
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.createWorkTalentDetail {
  min-height: 100vh;
  background: #f9f9f9;
  padding: 30px;
  box-sizing: border-box;
  .createWorkTalentDetail-t {
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;
    img {
      border-radius: 12px;
      width: 100%;
      height: 100%;
    }
  }
  .createWorkTalentDetail-b {
    position: relative;
    z-index: 2;
    border-radius: 16px 16px 0 0;
    margin-top: -10px;
    box-sizing: border-box;
    .name {
      font-size: 38px;
      font-weight: 600;
      color: #000000;
      line-height: 90px;
      position: relative;
      margin-left: 30px;
      &::before {
        position: absolute;
        content: "";
        width: 150%;
        height: 16px;
        background: #e2e2e2;
        border-radius: 4px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        z-index: -1;
      }
    }

    .intro {
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 40px;
      padding: 30px 34px 34px;
      box-sizing: border-box;
      margin-top: 30px;
      .remark {
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
      }
      p,
      span,
      div {
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
      }
      img {
        width: 100%;
      }
    }
  }
}
</style>
